<template>
  <travel-svg class="travel-svg" v-bind="vueBind" style="position: absolute; left: 0; top: 0" />
</template>

<script>
export default {
  name: 'Travel',
  props: {
    vueBind: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    TravelSvg: () => import('@assets/icons/travelbk.svg'),
  },
  mounted() {
    this.$emit('loaded');
  },
};
</script>

<style lang="scss">
.travel-svg {
  .st0{clip-path:url(#SVGID_2_);fill:#DCFDFF;}
	.st1{opacity:0.36;clip-path:url(#SVGID_2_);fill:#FCFCFC;}
	.st2{opacity:0.32;clip-path:url(#SVGID_2_);fill:#FFFFFF;}
	.st3{clip-path:url(#SVGID_2_);fill:#F0EEFF;}
	.st4{clip-path:url(#SVGID_2_);fill:#F6F5FC;}
	.st5{clip-path:url(#SVGID_2_);fill:#FCE9D0;}
	.st6{clip-path:url(#SVGID_2_);fill:#F7F9F2;}
	.st7{opacity:0.49;clip-path:url(#SVGID_2_);fill:#EAD4C1;}
	.st8{clip-path:url(#SVGID_2_);fill:#EAD4C1;}
	.st9{clip-path:url(#SVGID_2_);}
	.st10{fill:#53535F;}
	.st11{fill:#353540;}
	.st12{fill:#404049;}
	.st13{fill:#2C2C36;}
	.st14{fill:#0A0A14;}
	.st15{fill:#56565E;}
	.st16{fill:#FFFFFF;}
	.st17{fill:#CDDFF9;}
	.st18{fill:#F96A63;}
	.st19{fill:#49689F;}
	.st20{fill:#344770;}
	.st21{fill:#3E3E46;}
	.st22{fill:#4E4D56;}
	.st23{opacity:0.6;fill:#3E3E46;}
	.st24{fill:#F28A4B;}
	.st25{opacity:0.5;fill:#F28A4B;}
	.st26{fill:#EAE8E6;}
	.st27{fill:#D9D7D7;}
	.st28{opacity:0.6;fill:#EFF7FF;}
	.st29{fill:#EDE4DE;}
	.st30{fill:#232321;}
	.st31{fill:#3D3D3A;}
	.st32{fill:#4D4C4A;}
	.st33{opacity:0.4;fill:#3D3D3A;}
	.st34{fill:#4C4A48;}
	.st35{fill:#131210;}
	.st36{fill:#343432;}
	.st37{fill:none;}
	.st38{fill:#067C4F;}
	.st39{fill:#55682B;}
	.st40{fill:#486E87;}
	.st41{fill:#8B86D1;}
	.st42{clip-path:url(#SVGID_2_);fill:#AEDB58;}
	.st43{clip-path:url(#SVGID_2_);fill:#6D9E18;}
	.st44{clip-path:url(#SVGID_2_);fill:#598712;}
	.st45{clip-path:url(#SVGID_2_);fill:#BCE870;}
	.st46{opacity:0.67;clip-path:url(#SVGID_2_);fill:#89AA55;}
	.st47{clip-path:url(#SVGID_2_);fill:#DDF9A7;}
	.st48{clip-path:url(#SVGID_2_);fill:#BAD188;}
	.st49{clip-path:url(#SVGID_2_);fill:#95A568;}
	.st50{opacity:0.36;clip-path:url(#SVGID_2_);fill:#6B8744;}
	.st51{clip-path:url(#SVGID_2_);fill:#7B9B49;}
	.st52{opacity:0.29;clip-path:url(#SVGID_2_);fill:#FFFFFF;}
	.st53{clip-path:url(#SVGID_2_);fill:#33241A;}
	.st54{clip-path:url(#SVGID_2_);fill:#60936C;}
	.st55{opacity:0.56;clip-path:url(#SVGID_2_);fill:#3A3F26;}
	.st56{opacity:0.28;clip-path:url(#SVGID_2_);fill:#DDF9A7;}
	.st57{clip-path:url(#SVGID_2_);fill:#6B8744;}
	.st58{clip-path:url(#SVGID_2_);fill:#4B5E2A;}
	.st59{clip-path:url(#SVGID_2_);fill:#42372D;}
	.st60{opacity:0.15;clip-path:url(#SVGID_2_);fill:#46542D;}
	.st61{fill:#46542D;}
	.st62{fill:#EEE3E3;}
	.st63{fill:#D2C7C7;}
	.st64{fill:#E5DADA;}
	.st65{fill:#F7274C;}
	.st66{fill:#4EAECE;}
	.st67{fill:#AA9F9F;}
	.st68{fill:#685C5C;}
	.st69{fill:#7F7474;}
	.st70{opacity:0.7;}
	.st71{fill:#FFF7F7;}
	.st72{opacity:0.7;fill:#FFF7F7;}
	.st73{fill:#BEB3B3;}
}
</style>
